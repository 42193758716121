/* eslint-disable */
import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { SexRealityShow } from '@/store/sexRealityShow/types';

const namespace: string = 'sexRealityShow';
@Options({
	computed: {
		pages() {
			this.allPages = Math.ceil(this.getArchivesVideo.length / this.videoPerPage);
			return this.allPages;
		},
		paginatedUsers() {
			let from = (this.pageNumber - 1) *  this.videoPerPage;
			let to = from + this.videoPerPage;
			return this.getArchivesVideo.slice(from, to);
		},
		nextPage() {
			if(this.pageNumber <= this.allPages) this.pageNumber++;
		},
		setHandlePage() {
			if(this.handlePage <= this.allPages) this.pageNumber = this.handlePage;
		}
	}
})

export default class SexRealityShowViewingNoMoneyPage extends Vue {
	@State('sexRealityShow') sexRealityShow: SexRealityShow | undefined;
	@Action('fetchRealityShowModels', {namespace}) fetchRealityShowModels: any;
	@Action('storeModelViewingPerMinOrAll', {namespace}) storeModelViewingPerMinOrAll: any;
	@Getter('getRealityShowModels', {namespace}) getRealityShowModels: any;
	@Getter('getArchivesVideo', {namespace}) getArchivesVideo: any;
	@Getter('getRealityShowLeader', {namespace}) getRealityShowLeader: any;

	allPages: number = 0;
	videoPerPage: number = 9;
	pageNumber: number = 1;
	handlePage: number = 1;

	pageClick(page: number) {
		this.pageNumber = page
	}


	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			// this.storeModelViewingPeep({
			// 	peep: true
			// });
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			this.storeModelViewingPerMinOrAll({
				perMinutes: true
			})
			break;
		case 'allShow':
			this.storeModelViewingPerMinOrAll({
				allShow: true
			})
			break;
		default:
			break;
		}
	}

	created() {
		this.fetchRealityShowModels();
	}
}
